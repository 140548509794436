export const state = {
  appDialogDisplay: false,
  appDialogMessage: '',
  appDialogType: '',
  appDialogRedirect: false,
  barColor: 'rgba(5, 39, 68, .8), rgba(5, 39, 68, .8)',
  barImage: require('@/assets/PoleTags.jpg'),
  dctPoniSource: null,
  deletedAttachments: [],
  deletedForms: [],
  displayLoadingFieldVue: true,
  drawerState: null,
  drawerOverlays: false,
  drawerMiniVariant: false,
  employeeDataIsAvailable: false,
  fulcrumPoniSource: null,
  fvApplicationId: 27,
  fvLoadingMessage: 'Logging you in...',
  isOnline: false,
  loggedIn: false,
  loggedOut: false,
  loggingActivityType: {
    changed: 'Changed',
    clicked: 'Clicked',
    created: 'Created',
    deleted: 'Deleted',
    disconnected: 'Disconnected',
    edited: 'Edited',
    exported: 'Exported',
    impersonating: 'Impersonating',
    logIn: 'Logged In',
    logOut: 'Logged Out',
    navigation: 'Navigated To',
    reconnected: 'Reconnected',
    refresh: 'Refresh',
    refreshing: 'Refreshing App Data',
    saved: 'Saved',
    selected: 'Selected',
    started: 'Started',
    submitted: 'Submitted',
    viewed: 'Viewed',
  },
  refDataIsAvailable: false,
  taskSyncExecuting: false,
  taskSyncCounter: 0,
  tasksSynced: false,
  teamDataRefreshTime: '',
  unsubmittedAttachments: [],
  unsubmittedForms: [],
}
export const getters = {
  getDrawerState: (state) => state.drawerState,
  getDrawerOverlays: (state) => state.drawerOverlays,
  getDrawerMiniVariant: (state) => state.drawerMiniVariant,
  getBarImage: (state) => state.barImage,
  getBarColor: (state) => state.barColor,
  appDialogDisplay: (state) => state.appDialogDisplay,
  appDialogMessage: (state) => state.appDialogMessage,
  appDialogType: (state) => state.appDialogType,
  appDialogRedirect: (state) => state.appDialogRedirect,
  // deletedAttachments: (state) => state.unsubmittedAttachments,
  // deletedForms: (state) => state.deletedForms,
  displayLoadingFieldVue: (state) => state.displayLoadingFieldVue,
  displayUnableToLogIn: (state) => state.displayUnableToLogIn,
  employeeDataIsAvailable: (state) => state.employeeDataIsAvailable,
  fvLoadingMessage: (state) => state.fvLoadingMessage,
  isOnline: (state) => state.isOnline,
  loggedIn: (state) => state.loggedIn,
  loggedout: (state) => state.loggedOut,
  loggingActivityType: (state) => state.loggingActivityType,
  teamDataRefreshTime: (state) => state.teamDataRefreshTime,
  dctPoniSource: (state) => state.dctPoniSource,
  fulcrumPoniSource: (state) => state.fulcrumPoniSource,
  // refDataIsAvailable: (state) => state.refDataIsAvailable,
  taskSyncExecuting: (state) => state.taskSyncExecuting,
  // unsubmittedAttachments: (state) => state.unsubmittedAttachments,
  // unsubmittedForms: (state) => state.unsubmittedForms,
}
export const actions = {
  displayAppDialog ({ commit }, params) {
    commit('SET_APP_DIALOG_DISPLAY', params.display)
    commit('SET_APP_DIALOG_MESSAGE', params.message)
    commit('SET_APP_DIALOG_TYPE', params.type)
    commit('SET_APP_DIALOG_REDIRECT', params.redirectTo)
  },
  closeAppDialog ({ commit }, params) {
    commit('SET_APP_DIALOG_DISPLAY', params.display)
    // Reset dialog state on close
    commit('SET_APP_DIALOG_MESSAGE', '')
    commit('SET_APP_DIALOG_TYPE', '')
  },
  setLoggedIn ({ commit }, loggedIn) {
    commit('SET_LOGGED_IN', loggedIn)
  },
  setLoggedOut ({ commit }, loggedOut) {
    commit('SET_LOGGED_OUT', loggedOut)
  },
  setIsOnline ({ commit }, isOnline) {
    commit('SET_IS_ONLINE', isOnline)
  },
  setDisplayLoadingFieldVue ({ commit }, displayLoadingFieldVue) {
    commit('SET_DISPLAY_LOADING_FIELDVUE', displayLoadingFieldVue)
  },
  setDisplayUnableToLogIn ({ commit }, displayUnableToLogIn) {
    commit('SET_DISPLAY_UNABLE_TO_LOG_IN', displayUnableToLogIn)
  },
  setFvLoadingMessage ({ commit }, fvLoadingMessage) {
    commit('SET_FV_LOADING_MESSAGE', fvLoadingMessage)
  },
  updateDctPoniSource ({ commit }, dctPoniSource) {
    commit('UPDATE_DCT_PONI_SOURCE', dctPoniSource)
  },
  updateDeletedAttachments ({ commit }, deletedAttachments) {
    commit('UPDATE_DELETED_ATTACHMENTS', deletedAttachments)
  },
  updateDeletedForms ({ commit }, deletedForms) {
    commit('UPDATE_DELETED_FORMS', deletedForms)
  },
  updateEmployeeDataIsAvailable ({ commit }, employeeDataIsAvailable) {
    commit('UPDATE_EMPLOYEE_DATA_IS_AVAILABLE', employeeDataIsAvailable)
  },
  updateFulcrumPoniSource ({ commit }, fulcrumPoniSource) {
    commit('UPDATE_FULCRUM_PONI_SOURCE', fulcrumPoniSource)
  },
  updateRefDataIsAvailable ({ commit }, refDataIsAvailable) {
    commit('UPDATE_REF_DATA_IS_AVAILABLE', refDataIsAvailable)
  },
  updateTaskSyncExecuting ({ commit }, taskSyncExecuting) {
    commit('UPDATE_TASK_SYNC_EXECUTING', taskSyncExecuting)
  },
  updateTasksSynced ({ commit }, tasksSynced) {
    commit('UPDATE_TASKS_SYNCED', tasksSynced)
  },
  updateTeamDataRefreshTime ({ commit }, teamDataRefreshTime) {
    commit('UPDATE_TEAM_DATA_REFRESH_TIME', teamDataRefreshTime)
  },
  updateUnsubmittedAttachments ({ commit }, unsubmittedAttachments) {
    commit('UPDATE_UNSUBMITTED_ATTACHMENTS', unsubmittedAttachments)
  },
  updateUnsubmittedForms ({ commit }, unsubmittedForms) {
    commit('UPDATE_UNSUBMITTED_FORMS', unsubmittedForms)
  },
}
export const mutations = {
  UPDATE_DELETED_ATTACHMENTS (state, deletedAttachments) {
    state.deletedAttachments = deletedAttachments
  },
  UPDATE_DELETED_FORMS (state, deletedForms) {
    state.deletedForms = deletedForms
  },
  UPDATE_DCT_PONI_SOURCE (state, dctPoniSource) {
    state.dctPoniSource = dctPoniSource
  },
  UPDATE_EMPLOYEE_DATA_IS_AVAILABLE (state, employeeDataIsAvailable) {
    state.employeeDataIsAvailable = employeeDataIsAvailable
  },
  UPDATE_FULCRUM_PONI_SOURCE (state, fulcrumPoniSource) {
    state.fulcrumPoniSource = fulcrumPoniSource
  },
  UPDATE_REF_DATA_IS_AVAILABLE (state, refDataIsAvailable) {
    state.refDataIsAvailable = refDataIsAvailable
  },
  UPDATE_TASK_SYNC_EXECUTING (state, taskSyncExecuting) {
    state.taskSyncExecuting = taskSyncExecuting
    state.taskSyncCounter += taskSyncExecuting ? 1 : -1
  },
  UPDATE_TASKS_SYNCED (state, tasksSynced) {
    state.tasksSynced = tasksSynced
  },
  UPDATE_TEAM_DATA_REFRESH_TIME (state, teamDataRefreshTime) {
    state.teamDataRefreshTime = teamDataRefreshTime
  },
  UPDATE_UNSUBMITTED_ATTACHMENTS (state, unsubmittedAttachments) {
    state.unsubmittedAttachments = unsubmittedAttachments
  },
  UPDATE_UNSUBMITTED_FORMS (state, unsubmittedForms) {
    state.unsubmittedForms = unsubmittedForms
  },
  SET_DISPLAY_LOADING_FIELDVUE (state, displayLoadingFieldVue) {
    state.displayLoadingFieldVue = displayLoadingFieldVue
  },
  SET_DISPLAY_UNABLE_TO_LOG_IN (state, displayUnableToLogIn) {
    state.displayUnableToLogIn = displayUnableToLogIn
  },
  SET_FV_LOADING_MESSAGE (state, fvLoadingMessage) {
    state.fvLoadingMessage = fvLoadingMessage
  },
  SET_LOGGED_IN (state, loggedIn) {
    state.loggedIn = loggedIn
  },
  SET_LOGGED_OUT (state, loggedOut) {
    state.loggedOut = loggedOut
  },
  SET_IS_ONLINE (state, isOnline) {
    state.isOnline = isOnline
  },
  SET_APP_DIALOG_DISPLAY (state, payload) {
    state.appDialogDisplay = payload
  },
  SET_APP_DIALOG_MESSAGE (state, payload) {
    state.appDialogMessage = payload
  },
  SET_APP_DIALOG_TYPE (state, payload) {
    state.appDialogType = payload
  },
  SET_APP_DIALOG_REDIRECT (state, payload) {
    state.appDialogRedirect = payload
  },
  SET_DRAWER_STATE (state, payload) {
    state.drawerState = payload
  },
  SET_DRAWER_OVERLAYS (state, payload) {
    state.drawerOverlays = payload
  },
  SET_DRAWER_MINI_VARIANT (state, payload) {
    state.drawerMiniVariant = payload
  },
}
